import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import "./styel.css";

const SimpleSlider = () => {
  const quotes = [
    {
      text: "“When I look at the faces of children, I see endless possibilities. All they need is a little help, a little hope, a little love, and someone who believes in them.”",
      author: "Herbert Wigwe",
    },
    {
      text: "“The eradication of hunger is a key step towards a fairer, more peaceful world”",
      author: "Kofi Annan",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    arrows: false,
  };

  return (
    <Slider {...settings}>
      {quotes.map((quote, index) => (
        <div key={index} className="qoute">
          <h1>{quote.text}</h1>
          <p>{quote.author}</p>
        </div>
      ))}
    </Slider>
  );
};

export default SimpleSlider;
