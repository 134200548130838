import { Link , Outlet } from "react-router-dom";
import { Fragment } from "react";
import {ReactComponent as LogoIcon} from "../assets/Home.svg"
import "./navbar.css"


const Navbar = () => {
    return ( 
        <Fragment>
        <div className="navbar-container">
            <Link to='/'><LogoIcon/></Link>
            {/* <Link to='/CustomSlider'>slider</Link> */}
        </div>
        <Outlet/>
        </Fragment>
     );
}
 
export default Navbar;