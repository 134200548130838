import { Fragment, useState } from "react";
import ReactPlayer from "react-player";
import { ReactComponent as LogoIcon } from "../../assets/scatter.svg";
import { ReactComponent as ArrowIcon } from "../../assets/arrowdown.svg";
import { ReactComponent as Grouo48Icon } from "../../assets/Group 48.svg";
import { ReactComponent as RecIcon } from "../../assets/Rectangle 4.svg";
import { ReactComponent as Vic11Icon } from "../../assets/Vector11.svg";
import { ReactComponent as BelowIcon } from "../../assets/below.svg";
import { ReactComponent as SmallIcon } from "../../assets/small.svg";
import { ReactComponent as OnIcon } from "../../assets/onimage.svg";
import { ReactComponent as PlayIcon } from "../../assets/play.svg";
import { ReactComponent as CancelIcon } from "../../assets/iconoir_cancel.svg";
import { ReactComponent as GroupIcon } from "../../assets/Group over.svg";
import { ReactComponent as NutriIcon } from "../../assets/Vectornutri.svg";
import { ReactComponent as LogIcon } from "../../assets/Home.svg";
import { ReactComponent as InIcon } from "../../assets/VectorIN.svg";
import { ReactComponent as LiIcon } from "../../assets/VectorLI.svg";
import { ReactComponent as XIcon } from "../../assets/VectorX.svg";
import { ReactComponent as BigIcon } from "../../assets/Bigvector.svg";
import { ReactComponent as FastFood } from "../../assets/Red Fast Food Box With Handle.J07 2.svg";
import { ReactComponent as Banking } from "../../assets/Banking.svg";
import { ReactComponent as MoneyCanel } from "../../assets/MoneyCancle.svg";
import { ReactComponent as FormIcon } from "../../assets/Change.svg";
import SimpleSlider from "../../slider/slider";
import Spinner from "../../loading/spinner";
import axios from "axios";
import SliderComponent from "../../customslide/CustomSlider";
import Slider from "../qoutes/qoutesslider";
import "./home.css";

const defaultForm = {
  first_name: "",
  last_name: "",
  message: "",
  email: "",
};

const Home = () => {
  const [popUp, setPopup] = useState(false);
  const [payover, setPay] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [formFeild, setFormFeild] = useState(defaultForm);
  const { first_name, last_name, message, email } = formFeild;
  const [sucessMsg, setSucessMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handelChange = (e) => {
    const { name, value } = e.target;
    setFormFeild({ ...formFeild, [name]: value });
  };

  const reSetform = () => {
    setFormFeild(defaultForm);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    setLoading(true);

    axios
      .post(`https://usebasin.com/f/7022a74f5922`, formData)
      .then((response) => {
        setSucessMsg("Thank you message sent!");
        reSetform();
        setTimeout(() => {
          setSucessMsg("");
        }, 4000);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const copyAccountNumber = () => {
    const accountNumber = "2001939636";
    navigator.clipboard.writeText(accountNumber);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  };

  return (
    <Fragment>
      {payover && (
        <div className="pay-over">
          <div className="card">
            <MoneyCanel onClick={() => setPay(false)} className="cancel" />
            <Banking className="banking-svg" />
            <h1>Pay With Transfer</h1>
            <FastFood className="food" />
            <p>NUTRI-KID INITIATIVE</p>
            <p>2001939636</p>
            <div className="inner">
              <img src="https://i.ibb.co/7tqR154/Ellipse-13.png" alt="" />{" "}
              <h1>FCMB</h1>
            </div>
            <button onClick={copyAccountNumber}>
              {isCopied ? "Copied!" : "Copy Account Number"}
            </button>
          </div>
        </div>
      )}

      {popUp && (
        <div className="overlay">
          <CancelIcon onClick={() => setPopup(false)} />

          <ReactPlayer
            playing={popUp}
            url="https://smarttransexpress.s3.eu-north-1.amazonaws.com/WhatsApp+Video+2024-05-24+at+17.25.51.mp4"
            controls
            width="100%"
            height="100%"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </div>
      )}
      <div className="home-container">
        <div className="banner">
          <SimpleSlider />
        </div>

        <div className="who-are-we">
          <h1>Who We Are</h1>
          <p>
            Nutri-Kid Initiative is a non-profit organisation (NGO) aimed at
            tackling the issue of food insecurity in Nigeria. Specifically, the
            organisation focuses on providing nutrition to primary school aged
            children enrolled in government schooling who lack the consistency
            of a balanced diet that is required for adequate brain development
            and growth at their ages.
          </p>
        </div>

        <div className="logo">
          <LogoIcon />
        </div>

        <div className="mission">
          <h1>Mission Statement</h1>
          <p>
            We provide balanced and nutritious meals to schools in the poorest
            part of the country, to ensure that Nigeria’s children and the
            future of the country’s work force receive all the aid they need to
            ensure proper cognitive and physical development.
          </p>
          <ArrowIcon className="arrow-mission" />
          <Grouo48Icon className="group" />
        </div>

        <div className="our-reason">
          <div className="wrapper">
            <Vic11Icon className="vic11" />
            <BelowIcon className="below" />
            <SmallIcon className="small" />
            <div className="box-1">
              <h1>Our reason</h1>
              <p>
                Over 30% of African children experience stunting in their
                childhood. In Nigeria specifically, we have the second highest
                number of stunted children in the world.
              </p>
              <RecIcon />
              <p className="p">
                With an estimated 2 million children suffering from severe acute
                malnutrition (SAM) and about 22% of children being underweight.
              </p>
            </div>
            <div className="box-2">
              <img src="https://i.ibb.co/fv61pmX/Group-55.png" alt="" />
            </div>
            <div className="box-3">
              <p>
                These are harrowing statistics, and it is contributing to the
                widespread regression in cognitive development we can observe
                amongst Nigerians.
              </p>
              <RecIcon className="okay" />
              <div className="line"></div>
              <p>
                Nutri-Kid wants to ensure that Nigeria’s future is well fed, so
                they mature cognitively and physically at the appropriate level
                for their respective age groups.
              </p>
              <BigIcon className="oga" />
            </div>
            <div className="line" style={{ marginTop: "30px" }}></div>
            <div className="box-4">
              <p>
                Children need to be nourished optimally so they can develop
                properly as they grow up and be active contributors and
                participants to the environment around them.
              </p>
              <img
                src="https://i.ibb.co/cN6M6Yb/Group-56-2x-cropped.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="video-con">
          <OnIcon className="on" />
          <PlayIcon className="play" onClick={() => setPopup(true)} />
        </div>

        <div className="ga">
          <h1>Gallery</h1>
        </div>

        <div className="gallary-images">
          <SliderComponent />
        </div>

        <div className="banner-below">
          <div className="over">
            <div className="box1">
              <Slider />
            </div>
            <div className="box2">
              <NutriIcon />
            </div>
          </div>
          <GroupIcon />
        </div>

        <div className="our-sponsorer">
          <h1>Our sponsored schools</h1>
          <p>
            At Nutri-Kid, we recognize the vital role of proper nutrition in
            schools. We are committed to addressing malnutrition, school by
            school, to empower the next generation to thrive in their learning
            and development journey.
          </p>
        </div>

        <div className="sponsor-banner">
          <img
            src="https://i.ibb.co/68bFWmK/Wesley-ilisan-remo-outlines.png"
            alt=""
          />
        </div>

        <div className="work-for">
          <div className="box">
            <img
              src="https://i.ibb.co/bFS3sXh/782-7827900-hungry-children-png-croatian-africa-transparent-png-1.png"
              alt=""
            />
          </div>
          <div className="box2">
            <h1>How we work</h1>
            <p>
              We sponsor primary schools by providing hot nutritious lunches to
              the students, you can be a part of making this happen by donating
              below
            </p>
            <button onClick={() => setPay(true)}>Donate Now</button>
          </div>
        </div>

        <div className="form">
          <FormIcon />
          <form onSubmit={handleSubmit}>
            <h1>CONTACT US</h1>
            <div className="in">
              <input
                type="text"
                placeholder="First Name"
                name="first_name"
                value={first_name}
                onChange={handelChange}
                required
              />
              <input
                type="text"
                placeholder="Last Name"
                name="last_name"
                value={last_name}
                onChange={handelChange}
                required
              />
            </div>
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={email}
              onChange={handelChange}
              required
            />
            <textarea
              placeholder="Message"
              name="message"
              value={message}
              onChange={handelChange}
              required
              id=""
              cols="30"
              rows="10"
            ></textarea>
            <button disabled={loading}>
              {loading ? <Spinner /> : "Send Message"}
            </button>
            {sucessMsg && <p>{sucessMsg}</p>}
            {error && <span>{error}</span>}
          </form>
        </div>
      </div>
      <div className="footer">
        <div className="box1">
          <LogIcon />
          <p>© 2024 Company. All rights reserved.</p>
        </div>
        <div className="box2">
          <p>+234 809 189 3118</p>
          <p>12 Temple Road, Off Alfred Rewane Road, Ikoyi, Lagos</p>

          <div className="social">
            <a
              href="https://www.instagram.com/nutrikid.ng?igsh=MTFiY3BsYW50NzcwMw=="
              target="blank"
            >
              <InIcon />
            </a>
            <a href="/">
              <LiIcon />
            </a>
            <a href="https://x.com/nutri_kid?s=21&t=uMtAQPLrWeWx9MlhdyKn-w">
              <XIcon />
            </a>
          </div>
          <p className="p">© 2024 Company. All rights reserved.</p>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
