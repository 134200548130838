import React from "react";
import Slider from "react-slick";
import { ReactComponent as ArrowB } from "../assets/ArrowB.svg";
import { ReactComponent as ArrowF } from "../assets/ArrowF.svg";
import "./Slider.css";

const SliderComponent = () => {
  const images = [
    "https://i.ibb.co/QH6nD0c/Whats-App-Image-2024-04-01-at-3-02-56-PM.jpg",
    "https://i.ibb.co/b61SzWB/Whats-App-Image-2024-04-01-at-3-02-57-PM-7.jpg",
    "https://i.ibb.co/db9T68L/Whats-App-Image-2024-04-01-at-3-02-55-PM-1.jpg",
    "https://i.ibb.co/3rsBRfn/Whats-App-Image-2024-04-01-at-3-02-57-PM-1.jpg",
    "https://i.ibb.co/1nB4dmL/Whats-App-Image-2024-04-01-at-3-02-57-PM-2.jpg",
    "https://i.ibb.co/PNT1Kf5/Whats-App-Image-2024-04-01-at-3-02-57-PM-3.jpg",
    "https://i.ibb.co/ZG2SV59/Whats-App-Image-2024-04-01-at-3-02-57-PM-4.jpg",
    "https://i.ibb.co/RhB6LDq/Whats-App-Image-2024-04-01-at-3-02-55-PM.jpg",
    "https://i.ibb.co/9sYq94v/Whats-App-Image-2024-04-01-at-3-02-57-PM-5.jpg",
    "https://i.ibb.co/RDP7GDH/Whats-App-Image-2024-04-01-at-3-02-57-PM-6.jpg",
    "https://i.ibb.co/BKcqkWX/Whats-App-Image-2024-04-01-at-3-02-57-PM-8.jpg",
    "https://i.ibb.co/1qnMc6R/Whats-App-Image-2024-04-01-at-3-02-57-PM-9.jpg",
    // "https://i.ibb.co/dcrT2bK/Whats-App-Image-2024-04-01-at-3-02-57-PM-10.jpg",
    "https://i.ibb.co/vQV4Fd2/Whats-App-Image-2024-04-01-at-3-02-57-PM.jpg",
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    autoplay: true,
    slidesToScroll: 1,
    pauseOnHover: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slider-wrapper">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="slider-slide">
            <img src={image} alt={`Slide ${index}`} className="slider-image" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <ArrowB
      className={className}
      style={{
        ...style,
        display: "block",
        left: "0%",
        position: "absolute",
        top: "107%",
        zIndex: "999",
        width: "70px",
        height: "70px",
      }}
      onClick={onClick}
    />
  );
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <ArrowF
      className={className}
      style={{
        ...style,
        display: "block",
        right: "0%",
        position: "absolute",
        top: "107%",
        zIndex: "999",
        width: "70px",
        height: "70px",
      }}
      onClick={onClick}
    />
  );
};

export default SliderComponent;
