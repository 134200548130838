import { Routes, Route, useLocation } from "react-router";
import { Fragment, useEffect } from "react";
import Navbar from "./navbar/navbar";
import Home from "./routes/Home/home";
// import MultipleItems from "./customslide/CustomSlider";
import ScrollToTopButton from "./Scrolltotop";



const App = () => {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0,0)
  },[location])
  return ( 
    <Fragment>
    <Routes>
      <Route path="/" element={<Navbar/>}>
        <Route index element={<Home/>}/>
        {/* <Route path="/CustomSlider" element={<MultipleItems/>}/> */}
      </Route>
    </Routes>
    <ScrollToTopButton/>
    </Fragment>
   );
}
 
export default App;